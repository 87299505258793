import { Timestamp } from "@firebase/firestore/lite";

// import {} from './utils_view'
import * as main_view from "./main_view";
import * as model from "./model";
import { hideLoading, showError, showLoading, showSuccess } from "./utils_view";

const groupsOrderKey = "groups_order_";
const groupsKey = "groups_";
const tilesOrderKey = "tiles_order_";
const tilesKey = "tiles_";
const themeKey = "theme_";
const configKey = "config_";
const anonSuffix = "anon";
const lastGroupUpdateKey = "last_group_update_";
const lastLoggedUserKey = "last_logged_user";
const usersColl = "users";
const groupsColl = "groups";
const tilesColl = "tiles";

let loggedUser;

function init() {
  model.init();
  loggedUser = getLastLoggedUserLocal();
  onAuthChange(async (user) => {
    console.log("onAuthChange ", user);
    loggedUser = user;
  });
  main_view.init();
  checkInitParams();
}

function checkInitParams() {
  document.addEventListener("DOMContentLoaded", async () => {
    // Get the action to complete.
    const mode = getParameterByName("mode");
    // Get the one-time code from the query parameter.
    const actionCode = getParameterByName("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = getParameterByName("continueUrl");
    if (mode === "verifyEmail") {
      showLoading();
      await handleVerifyEmail(actionCode, continueUrl);
      hideLoading();
    } else if (mode === "emailVerified") {
      window.history.pushState({}, "", "/");
      showSuccess("E-mail verificado com sucesso!");
    }
  });
}

function getParameterByName(param) {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
}

function onAuthChange(callback) {
  model.onAuthChange(callback);
}

function getUserId() {
  return loggedUser?.uid ?? anonSuffix;
}

async function createUser(email, password) {
  try {
    loggedUser = await model.createUser(email, password);
    await sendVerificationEmail(loggedUser);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}
async function startUserOnline(config) {
  if (!loggedUser) return;
  try {
    await model.setDbData(usersColl, { config }, loggedUser.uid);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}
async function getUserDataOnline() {
  if (!loggedUser) return;
  try {
    const res = await model.getDbDoc(usersColl, loggedUser.uid);
    return res;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

async function loginUser(email, password) {
  try {
    loggedUser = await model.loginUser(email, password);
    setLastLoggedUserLocal(loggedUser);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

async function logout() {
  try {
    await model.logout();
    setLastLoggedUserLocal(null);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

async function sendVerificationEmail(user) {
  try {
    await model.sendVerificationEmail(user);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

async function handleVerifyEmail(actionCode, continueUrl) {
  try {
    await model.handleVerifyEmail(actionCode);
    window.location = continueUrl;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function setLastLoggedUserLocal(last_user) {
  model.saveToLocal(lastLoggedUserKey, JSON.stringify(last_user));
}

function getLastLoggedUserLocal() {
  const last_user = model.getFromLocal(lastLoggedUserKey);
  if (last_user) return JSON.parse(last_user);
  return null;
}

function saveConfigToLocal(config) {
  if (!config.last_update) config.last_update = getTimestamp();
  model.saveToLocal(`${configKey}${getUserId()}`, JSON.stringify(config));
  return config;
}

async function saveConfigOnline(config) {
  if (!loggedUser) return;
  try {
    await model.updateDbData(usersColl, { config }, loggedUser.uid);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

/**
 * Busca configurações salvas
 * @returns Object
 */
function getConfigFromLocal() {
  let config = model.getFromLocal(`${configKey}${getUserId()}`);
  if (!config) {
    //default
    config = {
      isOpenNewTab: true,
      isEditableMode: true,
    };
  } else {
    config = JSON.parse(config);
  }
  return config;
}

function onChangeConfig() {
  main_view.toggleEditableMode();
}

/**
 * Salva a ordenação localmente
 * @param {string} groupId ID do grupo
 * @param {Object} tiles_order Ordenação
 */
function saveTileOrderToLocal(groupId, tiles_order) {
  if (!tiles_order.last_update) tiles_order.last_update = getTimestamp();
  model.saveToLocal(`${tilesOrderKey}${groupId}`, JSON.stringify(tiles_order));
  return tiles_order;
}

async function saveTileOrderOnline(groupId, tiles_order) {
  if (!loggedUser) return;
  try {
    await model.updateDbData(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`,
      { tiles_order },
      groupId
    );
    await setLastGroupUpdateOnline(groupId);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function getTileOrderFromLocal(groupId) {
  const sOrder = model.getFromLocal(`${tilesOrderKey}${groupId}`);
  if (sOrder) {
    const tiles_order = JSON.parse(sOrder);
    return tiles_order;
  }
  return {
    order: [],
  };
}

function saveTileToLocal(itemData, groupId) {
  if (!itemData.last_update) itemData.last_update = getTimestamp();
  const items = getTilesFromLocal(groupId);
  const foundIndex = items.findIndex((item) => item.id === itemData.id);
  if (foundIndex > -1) {
    items.splice(foundIndex, 1, itemData);
  } else {
    items.push(itemData);
  }
  model.saveToLocal(`${tilesKey}${groupId}`, JSON.stringify(items));
  return itemData;
}

async function saveTileOnline(itemData, groupId) {
  if (!loggedUser) return true;
  try {
    await model.setDbData(
      `${usersColl}/${loggedUser.uid}/${groupsColl}/${groupId}/${tilesColl}`,
      itemData,
      itemData.id
    );
    await setLastGroupUpdateOnline(groupId);
    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function getTilesFromLocal(groupId) {
  const sItems = model.getFromLocal(`${tilesKey}${groupId}`);
  const items = sItems ? JSON.parse(sItems) : [];
  return items;
}

async function getTilesOnline(groupId) {
  if (!loggedUser) return;
  try {
    const res = await model.getDbCollection(
      `${usersColl}/${loggedUser.uid}/${groupsColl}/${groupId}/${tilesColl}`
    );
    // console.log('tiles', res);
    return res;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

// function getTileFromLocalById(id, groupId) {
//   const items = getTilesFromLocal(groupId);
//   return items.find((item) => item.id === id);
// }

function removeTileFromLocal(id, groupId) {
  const items = getTilesFromLocal(groupId);
  const foundIndex = items.findIndex((item) => item.id === id);
  if (foundIndex > -1) {
    const removed = items.splice(foundIndex, 1);
    model.saveToLocal(`${tilesKey}${groupId}`, JSON.stringify(items));
    return removed[0];
  }
  return false;
}

async function removeTileOnline(id, groupId) {
  if (!loggedUser) return true;
  try {
    await model.removeDbDoc(
      `${usersColl}/${loggedUser.uid}/${groupsColl}/${groupId}/${tilesColl}`,
      id
    );
    await setLastGroupUpdateOnline(groupId);
    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function removeAllTilesFromGroupLocal(groupId) {
  model.removeFromLocal(`${tilesKey}${groupId}`);
}

async function removeAllTilesFromGroupOnline(groupId) {
  if (!loggedUser) return true;
  try {
    const tiles = await getTilesOnline(groupId);
    if (!tiles) return;
    const aIds = tiles.map((tile) => {
      return tile.id;
    });
    await model.batchDelete(
      `${usersColl}/${loggedUser.uid}/${groupsColl}/${groupId}/${tilesColl}`,
      aIds
    );
    //não precisa chamar o setLastGroupUpdateOnline, já é chamado em removeGroupOnline no controller
    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function changeTileByGroupLocal(id, oldGroupId, newGroupId) {
  const itemData = removeTileFromLocal(id, oldGroupId);
  if (itemData) {
    saveTileToLocal(itemData, newGroupId);
  }
  return itemData;
}

async function changeTileByGroupOnline(itemData, oldGroupId, newGroupId) {
  if (!loggedUser) return true;
  try {
    const isRemoved = await removeTileOnline(itemData.id, oldGroupId);
    if (isRemoved) {
      const isSaved = await saveTileOnline(itemData, newGroupId);
      return isSaved;
    }
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

//Groups
function saveGroupOrderToLocal(groups_order) {
  if (!groups_order.last_update) groups_order.last_update = getTimestamp();
  model.saveToLocal(
    `${groupsOrderKey}${getUserId()}`,
    JSON.stringify(groups_order)
  );
  return groups_order;
}
async function saveGroupOrderOnline(groups_order) {
  if (!loggedUser) return;
  try {
    await model.updateDbData(usersColl, { groups_order }, loggedUser.uid);
    await setLastGroupUpdateOnline();
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function getGroupOrderFromLocal() {
  const sOrder = model.getFromLocal(`${groupsOrderKey}${getUserId()}`);
  if (sOrder) {
    const groups_order = JSON.parse(sOrder);
    return groups_order;
  }
  return { order: [] };
}

function saveGroupToLocal(groupData) {
  if (!groupData.last_update) groupData.last_update = getTimestamp();
  const groups = getGroupsFromLocal();
  const foundIndex = groups.findIndex((group) => group.id === groupData.id);
  if (foundIndex > -1) {
    groups.splice(foundIndex, 1, groupData);
  } else {
    groups.push(groupData);
  }
  model.saveToLocal(`${groupsKey}${getUserId()}`, JSON.stringify(groups));
  return groupData;
}
async function saveGroupOnline(groupData) {
  if (!loggedUser) return true;
  try {
    await model.setDbData(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`,
      groupData,
      groupData.id
    );
    await setLastGroupUpdateOnline();
    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function getGroupsFromLocal() {
  const sGroups = model.getFromLocal(`${groupsKey}${getUserId()}`);
  const groups = sGroups ? JSON.parse(sGroups) : [];
  return groups;
}
async function getGroupsOnline() {
  if (!loggedUser) return;
  try {
    const res = await model.getDbCollection(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`
    );
    // console.log('groups', res);
    return res;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}
async function getGroupDataOnline(groupId) {
  if (!loggedUser) return;
  try {
    const res = await model.getDbDoc(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`,
      groupId
    );
    return res;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

function removeGroupFromLocal(groupId) {
  const groups = getGroupsFromLocal();
  const foundIndex = groups.findIndex((group) => group.id === groupId);
  if (foundIndex > -1) {
    const removed = groups.splice(foundIndex, 1);
    model.saveToLocal(`${groupsKey}${getUserId()}`, JSON.stringify(groups));
    return removed[0];
  }
  return false;
}
async function removeGroupOnline(id) {
  if (!loggedUser) return true;
  try {
    await model.removeDbDoc(`${usersColl}/${loggedUser.uid}/${groupsColl}`, id);
    await setLastGroupUpdateOnline();
    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}

async function setLastGroupUpdateOnline(id) {
  if (!loggedUser) return;
  try {
    const last_group_update = getTimestamp();
    await model.updateDbData(usersColl, { last_group_update }, loggedUser.uid);
    if (id) {
      await model.updateDbData(
        `${usersColl}/${loggedUser.uid}/${groupsColl}`,
        { last_update: last_group_update },
        id
      );
    }
    setLastGroupUpdateLocal(last_group_update);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    showError(`${errorCode ? errorCode + " - " : ""}${errorMessage}`);
  }
}
function setLastGroupUpdateLocal(last_update) {
  if (!last_update) last_update = getTimestamp();
  model.saveToLocal(`${lastGroupUpdateKey}${getUserId()}`, last_update);
}

function getLastGroupUpdateLocal() {
  return model.getFromLocal(`${lastGroupUpdateKey}${getUserId()}`);
}

function getAnonGroupsFromLocal() {
  const sGroups = model.getFromLocal(`${groupsKey}${anonSuffix}`);
  const groups = sGroups ? JSON.parse(sGroups) : [];
  return groups;
}
function getAnonGroupsOrderFromLocal() {
  const sGroupsOrder = model.getFromLocal(`${groupsOrderKey}${anonSuffix}`);
  const groupsOrder = sGroupsOrder ? JSON.parse(sGroupsOrder) : { order: [] };
  return groupsOrder;
}
function copyAnonGroupsToUser() {
  const anonGroups = getAnonGroupsFromLocal();
  anonGroups.forEach((grp) => {
    saveGroupToLocal(grp);
  });
  setLastGroupUpdateLocal();
}
function copyAnonGroupsOrderToUser() {
  const anonGroupsOrder = getAnonGroupsOrderFromLocal();
  if (anonGroupsOrder.order.length > 0) saveGroupOrderToLocal(anonGroupsOrder);
}

function getDomainFromUrl(url) {
  const domain =
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/gim.exec(url)[0];
  return domain;
}

function saveTheme(value) {
  model.saveToLocal(`${themeKey}${getUserId()}`, value);
}

function getTheme() {
  return model.getFromLocal(`${themeKey}${getUserId()}`);
}

function getTimestamp() {
  return Timestamp.now().valueOf();
}

//sincronização
async function sendLocalGroupsToOnline() {
  const groupsLocal = getGroupsFromLocal();
  const groupsOnlineTotal = await getGroupsOnline();
  if (!groupsOnlineTotal) return loaded;
  const groupsOnline = groupsOnlineTotal.filter((grp) => !grp.deleted);
  const groupsToSend = [],
    tileOrdersToSend = [];
  for (let i = 0; i < groupsLocal.length; i++) {
    const grpLocal = groupsLocal[i];
    const grpOnline = groupsOnline.find((grp) => grp.id === grpLocal.id);
    if (!grpOnline || grpOnline.last_update < grpLocal.last_update) {
      groupsToSend.push(grpLocal);
    }
    if (grpOnline) {
      const local_tiles_order = getTileOrderFromLocal(grpLocal.id);
      if (grpOnline.tiles_order.last_update < local_tiles_order.last_update) {
        if (groupsToSend.some((gts) => gts.id === grpLocal.id)) {
          grpLocal.tiles_order = local_tiles_order.tiles_order;
        } else {
          tileOrdersToSend.push({
            id: grpLocal.id,
            tiles_order: local_tiles_order.tiles_order,
          });
        }
      }
    }
  }
  if (groupsToSend.length > 0) {
    await model.batchInsert(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`,
      groupsToSend
    );
    await sendLocalTilesToOnline(groupsToSend);
  }
  if (tileOrdersToSend.length > 0) {
    await model.batchInsert(
      `${usersColl}/${loggedUser.uid}/${groupsColl}`,
      tileOrdersToSend
    );
  }
}
async function sendLocalTilesToOnline(groupsToSend) {
  for (let i = 0; i < groupsToSend.length; i++) {
    const group = groupsToSend[i];
    const gTiles = getTilesFromLocal(group.id);
    await model.batchInsert(
      `${usersColl}/${loggedUser.uid}/${groupsColl}/${group.id}/${tilesColl}`,
      gTiles
    );
  }
}
async function loadOnlineGroupsToLocal() {
  const groupsLocal = getGroupsFromLocal();
  const groupsOnline = await getGroupsOnline();
  let loaded = false;
  if (!groupsOnline) return loaded;
  for (let i = 0; i < groupsOnline.length; i++) {
    const grpOnline = groupsOnline[i];
    if (grpOnline.deleted) {
      removeGroupFromLocal(grpOnline.id);
      loaded = true;
    } else {
      const grpLocal = groupsLocal.find((grp) => grp.id === grpOnline.id);
      if (!grpLocal || grpLocal.last_update < grpOnline.last_update) {
        saveGroupToLocal(grpOnline);
        await loadOnlineTilesToLocal(grpOnline.id);
        loaded = true;
      }
      if (
        !grpLocal ||
        (grpOnline.tiles_order &&
          grpLocal.tiles_order?.last_update <
            grpOnline.tiles_order?.last_update)
      ) {
        saveTileOrderToLocal(grpOnline.id, grpOnline.tiles_order);
        loaded = true;
      }
    }
  }
  return loaded;
}
async function loadOnlineTilesToLocal(groupId) {
  const tilesLocal = getTilesFromLocal(groupId);
  const tilesOnline = await getTilesOnline(groupId);
  if (!tilesOnline) return;
  for (let i = 0; i < tilesOnline.length; i++) {
    const tileOnline = tilesOnline[i];
    if (tileOnline.deleted) {
      removeTileFromLocal(tileOnline.id, groupId);
    } else {
      const tileLocal = tilesLocal.find((tile) => tile.id === tileOnline.id);
      if (!tileLocal || tileLocal.last_update < tileOnline.last_update) {
        saveTileToLocal(tileOnline, groupId);
      }
    }
  }
}

async function syncronize() {
  const userData = await getUserDataOnline();
  if (!userData) return;
  const updated = {
    config: false,
    groupsOrder: false,
    groups: false,
  };
  const localConfig = getConfigFromLocal();
  const localOrderGroup = getGroupOrderFromLocal();
  const lastGroupUpdateLocal = getLastGroupUpdateLocal();
  if (
    localConfig?.last_update &&
    (!userData.config ||
      !userData.config.last_update ||
      localConfig.last_update > userData.config.last_update)
  ) {
    //send config to online
    await saveConfigOnline(localConfig);
  } else if (
    userData.config &&
    userData.config.last_update &&
    (!localConfig?.last_update ||
      localConfig.last_update < userData.config.last_update)
  ) {
    //save online config to local
    saveConfigToLocal(userData.config);
    updated.config = true;
  }
  if (
    localOrderGroup?.last_update &&
    (!userData.groups_order ||
      !userData.groups_order.last_update ||
      localOrderGroup.last_update > userData.groups_order.last_update)
  ) {
    //send order to online
    await saveGroupOrderOnline(localOrderGroup);
  } else if (
    userData.groups_order &&
    userData.groups_order.last_update &&
    (!localOrderGroup?.last_update ||
      localOrderGroup.last_update < userData.groups_order.last_update)
  ) {
    //save online order to local
    saveGroupOrderToLocal(userData.groups_order);
    updated.groupsOrder = true;
  }
  if (
    lastGroupUpdateLocal &&
    (!userData.last_group_update ||
      userData.last_group_update < lastGroupUpdateLocal)
  ) {
    //send groups to online
    await sendLocalGroupsToOnline();
  } else if (
    userData.last_group_update &&
    (!lastGroupUpdateLocal || userData.last_group_update > lastGroupUpdateLocal)
  ) {
    //save online groups to local
    updated.groups = await loadOnlineGroupsToLocal();
    setLastGroupUpdateLocal(userData.last_group_update);
  }
  document.dispatchEvent(new CustomEvent("sync", { detail: { updated } }));
  console.log("syncronize", updated);
  return updated;
}
function onSyncronize(callback) {
  if (typeof callback === "function") {
    document.addEventListener("sync", (evt) => {
      callback(evt.detail.updated);
    });
  }
}

export {
  init,
  getLastLoggedUserLocal,
  saveTileOrderToLocal,
  getTileOrderFromLocal,
  saveTileOrderOnline,
  saveTileToLocal,
  saveTileOnline,
  getTilesFromLocal,
  getTilesOnline,
  // getTileFromLocalById,
  removeTileFromLocal,
  removeTileOnline,
  removeAllTilesFromGroupLocal,
  removeAllTilesFromGroupOnline,
  saveGroupOrderToLocal,
  saveGroupOrderOnline,
  getGroupOrderFromLocal,
  getLastGroupUpdateLocal,
  saveGroupToLocal,
  saveGroupOnline,
  getGroupsFromLocal,
  getGroupsOnline,
  removeGroupFromLocal,
  removeGroupOnline,
  // getIconLinkFromUrl,
  getDomainFromUrl,
  changeTileByGroupLocal,
  changeTileByGroupOnline,
  saveTheme,
  getTheme,
  saveConfigToLocal,
  getConfigFromLocal,
  getUserDataOnline,
  saveConfigOnline,
  onChangeConfig,
  copyAnonGroupsToUser,
  copyAnonGroupsOrderToUser,
  createUser,
  loginUser,
  onAuthChange,
  logout,
  startUserOnline,
  syncronize,
  onSyncronize,
  sendVerificationEmail,
};
